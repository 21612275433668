<template>
  <div class="study-list"> 
  
  
   
	  <PayStatusShow ref="PayStatusShow"   />
  
	  <MyUpdata ref="MyUpdata"   />
	   
	   <PayStatus ref="PayStatus"   />
	    
		   
	  
    <el-card>
      <el-form v-model="form" label-width="100">
        <el-form-item label="您已选择">
          <el-tag
              :key="tag"
              v-for="tag in tags"
              closable
              :disable-transitions="false"
			  	@close="handleClose(tag)"
				
				>
            {{tag.name}}
          </el-tag>
        </el-form-item>

        <el-form-item label="更多选择"> 
		  
		   <el-select v-if="show_close_doing_s_jname_list"  placeholder="教材书（资料）名称" @change="s_jname_Change" v-model="options.s_jname"  
		   filterable :filter-method="dataFilter">
		                              <el-option    v-for="(i,index) in s_jname_list" :value="i" :key="id">{{i}}</el-option>
		   </el-select>
			  
			  
          <el-select v-if="show_close_doing_s_banben_list"  v-model="options.s_banben"   @change="s_banben_Change"  size="mini" placeholder="第几版" style="width: 120px;">
            <el-option label="" :value="i"  v-for="(i,index) in s_banben_list"  >{{i}}</el-option>
          </el-select>
		  
          <el-select v-if="show_close_doing_s_shiyongren_list" size="mini"   @change="s_shiyongren_Change"  placeholder="封面标志性说明，适用人员，适用范围" style="width: 260px;"> 
			  <el-option label="" :value="i" v-for="(i,index) in s_shiyongren_list"  >{{i}}</el-option>
          </el-select>
		  
		  
          <el-select  v-if="show_close_doing_s_timu_list"  size="mini"   @change="s_timu_Change"   placeholder="题目、章节名称" style="width: 140px;"> 
			<el-option label="" :value="i"  v-for="(i,index) in s_timu_list"  >{{i}}</el-option> 
          </el-select>
		  
          <el-select  v-if="show_close_doing_s_zhubian_list"  size="mini"   @change="s_zhubian_Change"  placeholder="主编" style="width: 100px;"> 
            <el-option label=""  :value="i"  v-for="(i,index) in s_zhubian_list"  >{{i}}</el-option> 
          </el-select>
		  
		  
		  
		  
		  
		  
          <el-select   v-if="show_close_doing_s_fuzubain_list"  size="mini"   @change="s_fuzubain_Change"   placeholder="副主编" style="width: 100px;">
            <el-option label=""  :value="i"  v-for="(i,index) in s_fuzubain_list"  >{{i}}</el-option>
          </el-select>
		  
		  
          <el-select v-if="show_close_doing_s_chubanshe_list"  size="mini"   @change="s_chubanshe_Change"  placeholder="出版发行(社)" style="width: 140px;">
            <el-option label=""  :value="i"  v-for="(i,index) in s_chubanshe_list"  >{{i}}</el-option>
          </el-select>
		  
          <el-select v-if="show_close_doing_s_chubantime_list" size="mini"  @change="s_chubantime_Change" placeholder="出版日期" style="width: 120px;">
 
            <el-option label=""  :value="i"  v-for="(i,index) in s_chubantime_list"  >{{i}}</el-option>
          </el-select>
		  
          <el-select v-if="show_close_doing_s_imgname_list"   size="mini"  @change="s_imgname_Change"  placeholder="图片制作上传者姓名" style="width: 200px;">
            <el-option label=""  :value="i" v-for="(i,index) in s_imgname_list"  >{{i}}</el-option>
          </el-select>
  <!--        <el-select :value="form.name" size="mini" placeholder="图片制作上传时间" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select> -->
          <el-select v-if="show_close_doing_s_danwei_list"   size="mini"   @change="s_danwei_Change"  placeholder="图片制作上传者所属单位（首选）或曾履职单位，学习、毕业学校" style="width: 400px;">
            <el-option :value="i"   v-for="(i,index) in s_danwei_list"  >{{i}}</el-option>
          </el-select>
    <!--      <el-select :value="form.name" size="mini" placeholder="适用于哪年哪月测试观看" style="width: 200px;">
            <el-option label="" value=""></el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
    </el-card> 
	
	
	
	
	

    <el-card class="margin-top padding-bottom">
      <el-table style="width: 100%;" :data="rows" @row-click="ups">
        <el-table-column   align="center"
            type="index"  
            label="序号"
            width="50">
        </el-table-column>  
		
		  <el-table-column  align="center"
		      type="index"
		      label="上传者"
		      width="80">
		  </el-table-column>  
        <el-table-column  align="center"
            prop="title"
            label="标题"
            width="350">
        </el-table-column>
        <el-table-column  align="center"
            prop="title"
            label="标价"
            width="80">
        </el-table-column>
		
		<el-table-column  align="center"
		    prop="create_time"
		    label="上传时间"
		     >
		</el-table-column>
		
       <el-table-column
            prop="dcesc"
            label="操作22221">
			<template slot-scope="{row,column,$index}">
				<a  class="el-button el-button--primary el-button--small" @click.stop="goPayStatus(row)" >查看</a>
							<!-- 	<span   v-else-if="row.invoice_type === 1 "  class="el-button el-button--primary el-button--small"  @click="goInvoice(row)" >等待开票</span>
								<a      v-else="row.invoice_type === 2 " class="el-button el-button--primary el-button--small"  target="_blank"  :href="row.pdf"  >发票下载</a>
		 -->
			</template>
        </el-table-column>  
		
      </el-table>

      <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination> -->
	  
	  
	  
    </el-card>
	
	
	
  </div>
  
</template>

<script>
	import axios from "axios";
	
	import PayStatusShow from '../../components/PayStatusShow.vue'
	import PayStatus from '../../components/PayStatus.vue'
	import StudySearch from '../../components/StudySearch.vue'
	import MyUpdata from '../../components/MyUpdataLookTitle.vue'
	export default {
    name: "StudyList", 
    components: {  MyUpdata,StudySearch ,PayStatus,PayStatusShow},
    data() {
      return {
		  
		  //-----------------------------------
		  
		  show_close_doing_s_jname_list:true,
		  show_close_doing_s_banben_list:true,
		  show_close_doing_s_shiyongren_list:true,
		  show_close_doing_s_timu_list:true,
		  show_close_doing_s_zhubian_list:true,
		  
		  
		  show_close_doing_s_fuzubain_list:true,
		  show_close_doing_s_chubanshe_list:true,
		  show_close_doing_s_chubantime_list:true,
		  show_close_doing_s_imgname_list:true,
		  show_close_doing_s_danwei_list:true,
		  
		  //-----------------------------------
		  
		  
		  
		  //-----------检索 ------------ 
		  	s_jname_list:'',
		  	s_banben_list:'',
		  	s_shiyongren_list:'',
		  	s_timu_list:'',
		  	s_zhubian_list:'',
			
		  	s_fuzubain_list:'',
		  	s_chubanshe_list:'',
		  	s_chubantime_list:'',
		  	s_imgname_list:'',
		  	s_danwei_list:'',
		  //-----------检索 ------------
		  dialogShowImageVisible:true,
        tags: [
           
        ],
        form: {},
        rows: [],
		options: {
			s_jname:'',
			s_banben:'',
			s_shiyongren:'',
			s_timu:'',
			s_zhubian:'',
			s_fuzubain:'',
			s_chubanshe:'',
			s_chubantime:'',
			s_imgname:'',
			s_danwei:'',
		}
      }
    },
	

onShow() {
	// #ifdef H5
	let params = this.getRequestParams();
	let id = params['id']; 
	console.log(salt)//111
    let salt2 = params['salt2'];
	console.log(salt2)//222
	// #endif

 },
 
	
	
	created(e){   
		
		let local = location.href; 
		let type = this.getParam(local, "id");
	 
		const params = new URLSearchParams();
		params.append('uid', sessionStorage.getItem('uid')); 
		params.append('type', type);
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getUpdateDatas',params)
		.then((response) => {   
			this.rows = response.data.data;  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		
		
		
		
		axios.post(this.apiUrl+'/api/user/getStudySearchInfo',params)
		.then((response) => {   
			this.s_jname_list = response.data.data.s_jname_list; 
			this.s_banben_list = response.data.data.s_banben; 
			this.s_shiyongren_list = response.data.data.s_shiyongren; 
			this.s_timu_list = response.data.data.s_timu; 
			this.s_zhubian_list = response.data.data.s_zhubian; 
			this.s_fuzubain_list = response.data.data.s_fuzubain; 
			this.s_chubanshe_list = response.data.data.s_chubanshe; 
			this.s_chubantime_list = response.data.data.s_chubantime; 
			this.s_imgname_list = response.data.data.s_imgname; 
			
			this.s_danwei_list = response.data.data.s_danwei; 
			 
			
			 
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		
		
	},
	
	
    methods: {  
		
		
		s_danwei_Change(str){
			this.options.s_danwei = str;
			this.tags.push({
						'name': str,
						id: 's_danwei'
					});
			this.show_close_doing_s_danwei_list = false;
		},
		s_imgname_Change(str){
			this.options.s_imgname = str;
			this.tags.push({
						'name': str,
						id: 's_imgname'
					});
			this.show_close_doing_s_imgname_list = false;
		},
		
		s_chubantime_Change(str){
			this.options.s_chubantime = str;
			this.tags.push({
						'name': str,
						id: 's_chubantime'
					});
			this.show_close_doing_s_chubantime_list = false;
		},
		s_chubanshe_Change(str){
			this.options.s_chubanshe = str;
			this.tags.push({
						'name': str,
						id: 's_chubanshe'
					});
			this.show_close_doing_s_chubanshe_list = false;
		},
		
		s_fuzubain_Change(str){
			this.options.s_fuzubain = str;
			this.tags.push({
						'name': str,
						id: 's_fuzubain'
					});
			this.show_close_doing_s_fuzubain_list = false;
		},
		
		s_zhubian_Change(str){
			this.options.s_zhubian = str;
			this.tags.push({
						'name': str,
						id: 's_zhubian'
					});
			this.show_close_doing_s_zhubian_list = false;
		},
		
		s_timu_Change(str){
			this.options.s_timu = str;
			this.tags.push({
						'name': str,
						id: 's_timu'
					});
			this.show_close_doing_s_timu_list = false;
		},
		
		s_shiyongren_Change(str){
			this.options.s_banben = str;
			this.tags.push({
						'name': str,
						id: 's_shiyongren'
					});
			this.show_close_doing_s_shiyongren_list = false;
		},
		
		
		s_banben_Change(str){
			this.options.s_banben = str; 
			this.tags.push({
						'name': str,
						id: 's_banben'
					});
			this.show_close_doing_s_banben_list = false;
		},
		s_jname_Change(str){ 
			this.options.s_jname = str; 
			this.tags.push({
						'name': str,
						id: 's_jname'
					});
			
			this.show_close_doing_s_jname_list = false;
		},
		
		
		goPayStatus(e){  
		 
			
			const params = new URLSearchParams();
			params.append('uid', sessionStorage.getItem('uid')); 
			params.append('id', e.id);
			console.log(params);
			axios.post(this.apiUrl+'/api/user/getIsUpdateDatas',params)
			.then((response) => {   
				var ds = response.data.data;  
				if(ds == 1){
					this.$refs.PayStatusShow.open(e.id)
				}
				
				if(ds == 0){
					this.$refs.PayStatus.open(e.id,e.price)
				}
		
			}) 
			.catch(function(error) {
			  console.log(error);
			});
			
			
			
		},
		getParam(path, name) {
			var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
			if (reg.test(path))
			return unescape(RegExp.$2.replace(/\+/g, " "));
			return "";
        },
		ups(e) { 
		  this.$refs.MyUpdata.open(e.id)
		},
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1); 
		
		if(tag.id == "s_jname"){
			this.options.s_jname = "";
			this.show_close_doing_s_jname_list = true;
		}
		if(tag.id == "s_banben"){
			this.options.s_banben = "";
			this.show_close_doing_s_banben_list = true;
		}
		
		if(tag.id == "s_shiyongren"){
			this.options.s_shiyongren = "";
			this.show_close_doing_s_shiyongren_list = true;
		}
		
		if(tag.id == "s_timu"){
			this.options.s_timu = "";
			this.show_close_doing_s_timu_list = true;
		}
		
		
		if(tag.id == "s_zhubian"){
			this.options.s_zhubian = "";
			this.show_close_doing_s_zhubian_list = true;
		}
		
		if(tag.id == "s_fuzubain"){
			this.options.s_fuzubain = "";
			this.show_close_doing_s_fuzubain_list = true;
		}
		 
		
		if(tag.id == "s_chubanshe"){
			this.options.s_chubanshe = "";
			this.show_close_doing_s_chubanshe_list = true;
		}
		
		
		if(tag.id == "s_chubantime"){
			this.options.s_chubantime = "";
			this.show_close_doing_s_chubantime_list = true;
		}
		
		if(tag.id == "s_imgname"){
			this.options.s_imgname = "";
			this.show_close_doing_s_imgname_list = true;
		}
		
		if(tag.id == "s_danwei"){
			this.options.s_danwei = "";
			this.show_close_doing_s_danwei_list = true;
		}
      },

      /**
       * 打开某个页面
       * @param row
       */
      openPage(row) {
        console.log(row);
        let routeData = this.$router.resolve({
          name : '教材书籍'
        })
        console.log(routeData.href);
        window.open(routeData.href, "_blank")
      }
    }
  }
</script>

<style scoped lang="scss">

  .margin-top {
    margin-top: 20px;
  }

  .study-list {
    .el-card {
      /deep/ .el-card__body {
        padding-bottom: 0;
      }

      &.padding-bottom {
        padding-bottom: 20px;
      }
    }

    /deep/ .el-form-item__label {
      font-weight: bold;
    }

    /deep/ .el-form-item__content {
      margin-left: 80px;
    }

    .el-select {
      margin-right: 10px;
      margin-bottom: 8px;
    }

    .el-tag {
      color: $green;
      background-color: #ffffff;
      border-color: $green;
      margin-right: 10px;

      /deep/ .el-tag__close {
        color: $green;

        &:hover {
          color: #ffffff;
          background-color: $green;
        }
      }
    }

    .el-image:hover {
      cursor: pointer;
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }
  }

</style>
